.IdleTimeoutModal .btn {
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  min-width: 120px;
  min-height: 40px;
  border-radius: 3px;
  margin: auto;
}

.IdleTimeoutModal .icon-box i {
  color: #223c89;
  font-size: 70px;
}

.IdleTimeoutModal h4 {
  text-align: center;
  font-size: 26px;
  padding-top: 20px;
  margin: auto;
}

.IdleTimeoutModal ul {
  margin: 1rem;
}

.IdleTimeoutModal .close {
  position: absolute;
  top: -5px;
  right: -2px;
}

.IdleTimeoutModal {
  color: #636363;
  width: 600px;
}
.IdleTimeoutModal .modal-content {
  padding: 20px;
  border-radius: 5px;
  border: none;
  font-size: 16px;
}
.IdleTimeoutModal .modal-header {
  display: block;
  text-align: center;
  border-bottom: none;
  position: relative;
}
.IdleTimeoutModal .required {
  color: red;
}

.IdleTimeoutModal .modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
}
.IdleTimeoutModal .modal-footer a {
  color: #999;
}

.text-center {
  width: 100%;
}
