@font-face {
  font-family: 'Zeroes_1 W05 Regular';
  src: url('../fonts/zeroes-one/fc5124ad-a223-48d8-b5b7-bae6ec42f6ee.eot');
  src: url('../fonts/zeroes-one/fc5124ad-a223-48d8-b5b7-bae6ec42f6ee.eot?#iefix')
      format('eot'),
    url('../fonts/zeroes-one/1f599490-321e-4e0f-aa53-bcd8ac038144.woff2')
      format('woff2'),
    url('../fonts/zeroes-one/1fd5fbdd-be09-4180-9ffa-ee64e9a393e7.woff')
      format('woff'),
    url('../fonts/zeroes-one/1b8e8bc8-9449-469a-ad21-733cb90a3470.ttf')
      format('truetype');
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar {
  font-family: 'Oswald Light', sans-serif;
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-brand {
  margin-left: 10px;
}

.navbar-brand a:visited {
  color: #fff !important;
}

.navbar-brand a {
  color: #fff !important;
  text-decoration: none;
}

.font-awesome {
  padding-top: 1px;
  padding-right: 12px;
  font-size: 1.6em;
}

.font-awesome a:visited {
  color: White;
}

.col-centered {
  float: none;
  margin: 0 auto;
}

.custom-card-title {
  color: black;
  font-size: 0.75rem;
}

html,
body {
  /* min-height: 100%;
  min-height: 100vh; */
  height: 100%;
  font-size: 18px;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 18px;
}
input[type='file'] {
  width: 100%;
}

.logo {
  font-family: 'Zeroes_1 W05 Regular', sans-serif;
  letter-spacing: 3px;
}

.navbar-nav {
  width: 80%;
}

.modal-65w {
  max-width: 40%;
}

.modal-image-center {
  display: block;
  margin-left: 50%;
  margin-right: 50%;
  max-width: 70%;
  height: auto;
}

.center {
  margin: auto;
  width: 50%;
  max-width: 400px;
  min-width: 150px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
}
div.loader {
  font-size: 100px;
}

.loaded {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 10px;
}
/*
* {
  background-color: rgba(255, 0, 0, 0.2);
}
* * {
  background-color: rgba(0, 255, 0, 0.2);
}
* * * {
  background-color: rgba(0, 0, 255, 0.2);
}
* * * * {
  background-color: rgba(255, 0, 255, 0.2);
}
* * * * * {
  background-color: rgba(0, 255, 255, 0.2);
}
* * * * * * {
  background-color: rgba(255, 255, 0, 0.2);
}
* * * * * * * {
  background-color: rgba(255, 0, 0, 0.2);
}
* * * * * * * * {
  background-color: rgba(0, 255, 0, 0.2);
}
* * * * * * * * * {
  background-color: rgba(0, 0, 255, 0.2);
} */

.uphie-main-wrap .bg-custom {
  color: #ffffff;
  background-color: #007ac3;
}

.uphie-main-wrap .navbar {
  font-family: unset;
  padding: 0 !important;
  min-height: 60px !important;
}

.uphie-main-wrap .navbar .navbar-brand a {
  color: white;
  text-decoration: none;
  font-size: 1.4rem;
  font-family: 'Zeroes_1 W05 Regular', sans-serif;
  letter-spacing: 3px;
}

.uphie-main-wrap .navbar .navbar-collapse .container .navbar-nav .active {
  background-color: white !important;
  color: #007ac3 !important;
  padding: 1rem 1rem;
}

.uphie-main-wrap .navbar .navbar-collapse .navbar-nav .nav-link,
.uphie-main-wrap .navbar .navbar-collapse .navbar-nav .nav-link:visited,
.uphie-main-wrap .navbar .navbar-collapse .mr-sm-2 .nav-link {
  color: white;
  padding: 1rem 1rem;
}

.uphie-main-wrap .navbar .navbar-collapse .mr-sm-2 .account {
  padding: 1rem 0.5rem;
}

.uphie-main-wrap .navbar .navbar-collapse .mr-sm-2 .nav-item .dropdown-menu a,
.uphie-main-wrap .navbar .navbar-collapse .mr-sm-2 .nav-item .dropdown-menu .btn-link,
.uphie-main-wrap .navbar .navbar-collapse .navbar-nav .nav-item .dropdown-menu a,
.uphie-main-wrap .navbar .navbar-collapse .navbar-nav .nav-item .dropdown-menu button.nav-link {
  color: #007ac3;
}
.uphie-main-wrap .navbar .navbar-collapse .navbar-nav .nav-item .dropdown-menu button.nav-link {
  background: transparent;
  width: 100%;
  text-align: left;
  border: none;
}
.uphie-main-wrap .navbar
  .navbar-collapse
  .navbar-nav
  .nav-item
  .dropdown-menu
  button.nav-link:hover {
  background-color: #007ac3 !important;
  color: white !important;
}
.uphie-main-wrap .navbar .navbar-collapse .mr-sm-2 .nav-item .dropdown-menu .active,
.uphie-main-wrap .navbar .navbar-collapse .mr-sm-2 .nav-item .dropdown-menu .btn-link:active,
.uphie-main-wrap .navbar
  .navbar-collapse
  .container
  .navbar-nav
  .nav-item
  .dropdown-menu
  .active,
.uphie-main-wrap .navbar .navbar-collapse .mr-sm-2 .nav-item .dropdown-menu a:hover,
.uphie-main-wrap .navbar .navbar-collapse .mr-sm-2 .nav-item .dropdown-menu .btn-link:hover,
.uphie-main-wrap .navbar .navbar-collapse .navbar-nav .nav-item .dropdown-menu a:hover {
  background-color: #007ac3 !important;
  color: white !important;
}

.uphie-main-wrap .navbar .navbar-collapse .container .navbar-nav .nav-link-margin {
  margin-right: 1rem;
}

.uphie-main-wrap .navbar .navbar-collapse .mr-sm-2 .nav-item .dropdown-menu .btn-link {
  width: 100%;
  text-align: left;
  border: 0;
  border-radius: 0;
}

.uphie-main-wrap .navbar .navbar-collapse .mr-sm-2 .nav-item .dropdown-menu {
  min-width: unset;
}
