.Forbidden {
  padding: 80px 0;
  text-align: center;
}

.Forbidden h4 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  margin: 0 auto;
  width: 100%;
}

.Forbidden p {
  color: #999;
}
